<script setup lang="ts">

import BaseLine from '@/components/Lines/BaseLine.vue'
import HomeSlider from '@/components/Sliders/HomeSlider.vue'
import HomeCatalog from '@/components/Catalogs/HomeCatalog.vue'
import CreateAccount from '@/components/Register/CreateAccount.vue'
import CreateAccount2 from '@/components/Register/CreateAccount2.vue'
import { computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useCatalogStore } from '@/stores/catalog'

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)
const catalog = useCatalogStore()
const catalogItems = computed(() => catalog.items)
const catalogLoaded = computed(() => catalog.isLoaded)

onMounted(() => {
  catalog.fetchCatalogAsync()
})
</script>

<template>
<div class="base-line">
  <BaseLine text="твое личное время"/>
</div>
<div v-if="catalogLoaded" class="home-slider">
  <HomeSlider :catalog="catalogItems" />
</div>
<CreateAccount v-if="!isAuth"/>
<div v-if="catalogLoaded" class="home-catalog">
  <HomeCatalog :catalog="catalogItems" />
</div>
<CreateAccount2 v-if="!isAuth"/>
</template>
