<template>
<div class="back-line d-flex align-items-center">
  <slot />
</div>
</template>

<style scoped lang="less">
.back-line {
  position: sticky;
  top: 60px;
  z-index: 100;
  height: 40px;
  color: var(--typography-primary-color);
  background: var(--secondary-color);
}
</style>
