import { defineStore } from 'pinia'
import { IUser } from './types'
import { useAuthStore } from '../auth'
import { ref } from 'vue'
import { getUserInfoById, getUserTheme, userUpdate } from '@/api'

export const useUserStore = defineStore('user-store', () => {
  const authStore = useAuthStore()

  const user = ref<IUser | null>(null)
  const theme = ref<string>('b')
  const loaded = ref(false)
  const error = ref('')

  const setUser = (value: any) => {
    loaded.value = true
    user.value = value
  }
  const setUserError = (value: string) => {
    error.value = value
  }
  const getUserAsync = async () => {
    if (authStore.isAuth && authStore.username) {
      try {
        const data = await getUserInfoById(authStore.username)
        await getUserTheme(authStore.username)
          .then((response) => {
            theme.value = response.data.theme
          })
          .catch((e) => {
            console.log('Theme error', e)
          })
        setUser(data.data)
        return Promise.resolve(data.data)
      } catch (errorCatch) {
        setUserError('error')
        console.log(errorCatch)
        // return Promise.reject(errorCatch)
      }
    }

    return undefined
  }
  const getThemeAsync = async () => {
    if (authStore.isAuth && authStore.username) {
      return Promise.resolve(theme.value)
    }
    return undefined
  }

  const setTheme = async (theme: string) => {
    if (authStore.isAuth && authStore.username) {
      try {
        const description = { ...{ theme: theme }, ...user.value?.description }
        const data = await userUpdate({ ...user.value, ...{ description: description } })
        return Promise.resolve(data.data)
      } catch (errorCatch) {
        console.log(errorCatch)
      }
    }
  }

  return {
    user,
    theme,
    loaded,
    error,
    setUser,
    setTheme,
    setUserError,
    getUserAsync,
    getThemeAsync
  }
})
