import { RouteRecordRaw } from 'vue-router'
import ProfileView from '@/views/profile/ProfileView.vue'
import ProfileInterfaceView from '@/views/profile/ProfileInterfaceView.vue'
import { middleware } from '@/helpers/auth'

const profileRoutes: Array<RouteRecordRaw> = [
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter: [middleware.isAuth]
  },
  {
    path: '/profile/interface',
    name: 'profile-interface',
    component: ProfileInterfaceView,
    beforeEnter: [middleware.isAuth]
  }
]

export default profileRoutes
