<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, unref, watch } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { useRoute } from 'vue-router'
import { getMediaById, IMedia, likeUpdateById } from '@/api'
import Hls from 'hls.js'
import { formatTime } from '@/helpers/'
import Popper from 'vue3-popper'
import { useShare } from '@vueuse/core'
import { isClient } from '@vueuse/shared'
import { API_MEDIA } from '@/helpers/globalVars'
import SeriesSlider from '@/components/Sliders/SeriesSlider.vue'

const route = useRoute()
const mediaId = computed(() => route.params.media as string)
const media = ref<IMedia | null>(null)
const hls = ref()
const containerVideo = ref<HTMLVideoElement | null>(null)
const volume = ref(1)
const encodings = ref([])
const currentBitrate = ref('')
const likes = ref(0)
const isLiked = ref(null)
const wrapperProgress = ref()
const currentProgress = ref('0px')
const progressWidth = ref(0)
const isPlaying = ref(false)
const isOverLayHidden = ref(false)
const isInfoBarHidden = ref(false)
const overlayTimer = ref()
const infoBarTimer = ref()
const currentTime = ref('00:00')
const durationTime = ref('00:00')
const options = ref({
  title: 'YKVIK.COM',
  text: '',
  url: isClient ? location.href : ''
})

const loadMediaDataAsync = async () => {
  try {
    const { data } = await getMediaById(unref(mediaId))
    media.value = data
    const description = data.description.toString().replace(/\\n/g, '<br>')
    media.value = { ...media.value, ...{ description: JSON.parse(description) } }
    likes.value = media.value.likes
    Object.keys(media.value.hls_info).map((key, value) => {
      const bitrate = parseInt(key)
      const url = media.value.hls_info[key].toString()
      if (!isNaN(parseInt(key)) && url.includes('stream')) {
        if (currentBitrate.value === '') {
          currentBitrate.value = bitrate
        }
        encodings.value = { ...encodings.value, ...{ [bitrate]: url } }
      }
      return value
    })
  } catch (error) {
    console.error(error, 'error')
  }
}
const initPlayer = () => {
  loadMediaDataAsync().then(() => {
    hls.value = new Hls()
    hls.value.loadSource(`${API_MEDIA}${encodings.value[currentBitrate.value]}`)
    hls.value.attachMedia(document.getElementById('containerVideo'))
    hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
      isLiked.value = null
      containerVideo.value.volume = volume.value
      containerVideo.value.addEventListener('canplay', () => {
        try {
          const hasHours = (containerVideo.value.duration / 3600) >= 1.0
          durationTime.value = formatTime(containerVideo.value.duration, hasHours)
          containerVideo.value.play().catch(() => {
            isOverLayHidden.value = false
            isInfoBarHidden.value = false
          })
        } catch (e) {
          console.log('Error start auto play')
        }
      })
      containerVideo.value.addEventListener('play', (e) => {
        isPlaying.value = true
        setTimeout(() => {
          if (isPlaying.value) {
            isOverLayHidden.value = true
            isInfoBarHidden.value = true
          }
        }, 1000)
      })
      containerVideo.value.addEventListener('pause', () => {
        isPlaying.value = false

        if (overlayTimer.value) {
          clearTimeout(overlayTimer.value)
        }
        if (infoBarTimer.value) {
          clearTimeout(infoBarTimer.value)
        }
        isOverLayHidden.value = false
        isInfoBarHidden.value = false
      })
      containerVideo.value.addEventListener('timeupdate', (e) => {
        if (!isPlaying.value) {
          isPlaying.value = true
          setTimeout(() => {
            if (isPlaying.value) {
              isOverLayHidden.value = true
              isInfoBarHidden.value = true
            }
          }, 1000)
        }
        if (!containerVideo.value?.duration && !progressWidth.value) {
          return true
        }
        try {
          if (progressWidth.value === 0 && wrapperProgress.value.offsetWidth) {
            progressWidth.value = wrapperProgress.value.offsetWidth
          }

          const hasHours = (containerVideo.value.duration / 3600) >= 1.0
          currentTime.value = formatTime(containerVideo.value.currentTime, hasHours)

          const progress = Math.floor(containerVideo.value.currentTime) / Math.floor(containerVideo.value.duration)
          currentProgress.value = Math.floor(progress * progressWidth.value) + 'px'
        } catch (e) {
          console.log('Time error', e)
        }
      })
      containerVideo.value.addEventListener('error', (e) => {
        console.log('Error', e)
      })
    })
  })
}

watch(mediaId, () => {
  initPlayer()
})

const setTime = (e) => {
  if (!containerVideo.value?.duration) {
    return true
  }
  if (wrapperProgress.value?.offsetWidth) {
    progressWidth.value = wrapperProgress.value.offsetWidth
  }
  const currentPosition = e.offsetX > 0 ? e.offsetX : 0
  containerVideo.value.currentTime = (currentPosition / progressWidth.value) * containerVideo.value.duration
}
const setBitrate = async (val) => {
  try {
    const newBitrate = encodings.value[val]
    currentBitrate.value = val
    const time = containerVideo.value.currentTime
    await hls.value.loadSource(`${API_MEDIA}${newBitrate}`)
    containerVideo.value.currentTime = time
  } catch (error) {
    console.log(error)
  }
}
const likeUpdate = async () => {
  try {
    isLiked.value = await likeUpdateById(unref(mediaId))
  } catch (error) {
    console.log(error)
  }
}
const fullScreen = () => {
  function requestFullScreen (element) {
    const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen
    if (requestMethod) {
      requestMethod.call(element)
    }
  }
  if (document.fullscreenElement) {
    document.exitFullscreen()
  } else {
    const wrapper = document.getElementsByClassName('player-layout')[0]
    requestFullScreen(wrapper)
  }
}
const openShare = () => {
  return share().catch(err => err)
}
const toggleStateVideo = () => {
  try {
    if (isPlaying.value) {
      containerVideo.value.pause()
    }
  } catch (e) {
    console.log('Error get state', e)
  }
  return isPlaying.value
}
const toggleVolume = () => {
  volume.value = Number(!volume.value)
  containerVideo.value.volume = volume.value
}
const { share, isSupported } = useShare(options)

onMounted(() => {
  initPlayer()
})

onBeforeMount(() => {
  if (containerVideo.value) {
    containerVideo.value.removeEventListener('canplay')
    containerVideo.value.removeEventListener('play')
    containerVideo.value.removeEventListener('pause')
    containerVideo.value.removeEventListener('timeupdate')
    containerVideo.value = null
    hls.value.destroy()
  }
})
</script>

<template>
<div v-if="media" @click="toggleStateVideo" class="player">
  <video playsInline ref="containerVideo" id="containerVideo" class="player-preview__background"/>
  <div class="player-actions" :class="[!isInfoBarHidden ? 'info': '', isOverLayHidden ? 'play' : 'pause']">
    <transition name="fade">
      <div class="player-actions__series-wrapper full-width d-flex" v-if="!isOverLayHidden">
        <SeriesSlider :seriesList="media.description.episodes_list"/>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__button pr-20 d-flex justify-center" v-if="!isOverLayHidden">
        <CircleButton @click="containerVideo.play()" v-if="!isPlaying" class="btn-player__action" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
        <CircleButton @click="containerVideo.pause()" v-else class="btn-player__action" name="pause" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__info" v-if="!isInfoBarHidden">
        <div class="full-screen">
          <CircleButton @click="fullScreen" name="resize-full" :iconWidth="24" :iconHeight="24" view-box="0 0 24 24"/>
        </div>
        <h2>{{media.description.title}} - {{media.description.episode_num}} серия</h2>
        <p class="category">{{media.description.episode_spec}}</p>
        <div class="controls mb-20">
          <div class="control-left">
            <CircleButton @click="likeUpdate" :name="!isLiked ? 'like' : 'saved-like'" :iconWidth="18" :iconHeight="15.96" view-box="0 0 18 15.96">{{likes && !isLiked ? '' : isLiked}}</CircleButton>
          </div>
          <div class="control-right">
            <CircleButton @click="toggleVolume" :name="volume ? 'volume':'mute'" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <CircleButton v-if="isSupported" @click="openShare" name="upload" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <div class="btn_group p-relative">
              <Popper class="popper">
                <CircleButton name="empty">{{currentBitrate}}p</CircleButton>
                <template #content>
                  <CircleButton class="mb-10" :bg="bitrate == currentBitrate ? 'gradient2':'border'" v-for="(encode, bitrate) in encodings" @click="setBitrate(bitrate)" :key="encode" popovertarget="encodes_list" name="empty">{{bitrate}}p</CircleButton>
                </template>
              </Popper>
            </div>
            <router-link v-if="media.description" :to="{name: 'project', params: {id: media?.description.playlist_id}, query: {series: media.description.episode_num}}">
              <CircleButton name="chevron-down" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            </router-link>
          </div>
        </div>
        <div class="progress mb-20">
          <div class="progress-time__current">{{currentTime}}</div>
          <div @click="setTime" ref="wrapperProgress" class="progress-bar">
            <span :style="{width: currentProgress}" class="progress-bar__current" />
          </div>
          <div class="progress-time__ended">{{durationTime}}</div>
        </div>
      </div>
    </transition>
  </div>
</div>
</template>

<style scoped lang="less">
:root {
  --scrollbarBgColor: rgba(0, 0, 0, .05);
  --scrollbarThumbColor: rgba(0, 0, 0, .15);
  --scrollbarThumbColorSecond: rgba(255, 255, 255, .7);
  --scrollbarWidth: 16px;
  --scrollbarBorder: 0 solid var(--scrollbarBgColor);
  --scrollbarBorderRadius: calc(var(--scrollbarWidth) / 4);
}
#app {
  display: flex;
  margin: 0 auto;
}
.player {
  position: relative;
  width: auto;
  display: inline-block;
  margin: 0 auto;
  &-preview {
    &__background {
      height: 100vh;
      transition: opacity 0.5s ease;
      max-width: 100%;
    }
  }
  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    align-items: center;
    //height: 100vh;
    transition: opacity 0.5s ease;
    top: 0;
    bottom: 0;
    width: 100%;
    &.info {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      &.pause {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    &__series {
      &-wrapper {
        position: absolute;
        top: 20px;
        left: 10px;
        width: 100%;
        padding-right: 20px;
        padding-bottom: 5px;
      }
    }
    &__info {
      width: 100%;
      padding: 0 10px;
      .full-screen {
        position: absolute;
        top: 80px;
        right: 5px;
      }
      .category {
        font-family: Wix Madefor Display,serif;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        text-transform: uppercase;
      }
      .controls {
        display: flex;
        justify-content: space-between;
        .control {
          &-left {
            .btn {
              margin-right: 10px;
            }
          }
          &-right {
            display: flex;
            & > .btn {
              margin-right: 10px;
              &_group {
                margin-right: 10px;
              }
              .popper {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btn {
                  text-align: center;
                }
              }
            }
            .btn:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .progress {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        font-family: Wix Madefor Display,serif;
        font-size: 8px;
        font-weight: 600;
        line-height: 14px;
        color: #ffffff;
        &-bar {
          width: 100%;
          height: 2px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 2px;
          position: relative;
          cursor: pointer;
          &__current {
            display: inline-flex;
            height: 2px;
            background: linear-gradient(90deg, #9FE870 0%, #3FD9FB 100%);
            position: absolute;
          }
        }
        &-time {
          &__current {
            margin-right: 10px;
          }
          &__ended {
            margin-left: 10px;
          }
        }
      }
    }
    &__button {
      height: 100%;
      align-items: center;
      .btn {
        background: rgba(217, 217, 217, 0.1);
        border: 2px solid rgba(217, 217, 217, 0.1);
      }
    }
  }
}
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
