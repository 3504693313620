<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { computed, defineProps } from 'vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import PreviewPlayer from '@/components/Players/PreviewPlayer.vue'
import { IPlaylistItem } from '@/api'
import { useRoute } from 'vue-router'

defineProps<{
  playlistMedia: IPlaylistItem[];
}>()

const route = useRoute()
const seriesNumber = computed(() => route.query.series as string)

const onSwiper = (swiper) => {
  if (parseInt(seriesNumber.value)) {
    swiper.slideTo(parseInt(seriesNumber.value) - 1)
  }
}

</script>

<template>
  <swiper
    @init="onSwiper"
    :loop="true"
    :slidesPerView="'auto'"
    :space-between="20"
    class="slider"
  >
    <swiper-slide :data-hash="`slide${index + 1}`" :key="`media_${index}`" v-for="(media, index) in playlistMedia" class="series">
      <router-link :to="{name: 'project-player', params: {media: media?.friendly_token}}">
        <PreviewPlayer :overlay="false" class="series__background" :media="media" />
        <div class="series__wrapper">
          <div class="ribbons">
            <BaseButton class="uppercase" bg="none" border="default">Серия {{index + 1}}</BaseButton>
          </div>
          <div class="series__info">
            <h2 class="series__info-title">{{media?.title}}</h2>
            <h6 class="series__info-category uppercase">{{media?.category}}</h6>
          </div>
        </div>
      </router-link>
    </swiper-slide>
  </swiper>
</template>

<style scoped lang="less">
.slider {
  padding: 20px 10px;
  .series {
    width: auto;
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    a {
      position: relative;
    }
    &__background {
      object-fit: cover;
      width: 100%;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
@media (max-width:767px){
  .slider {
    .series {
      width: 75%;
    }
  }
}
</style>
