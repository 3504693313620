<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})
</script>

<template>
<router-link :to="{name: 'project', params: {id: props.item?.url}}" class="item" :class="{disabled: props.item?.isBlocked}">
  <img class="item__background" :class="{disabled: props.item?.isBlocked}" :src="props.item?.thumbnail" :alt="props.item?.title" />
  <div class="item__wrapper">
    <div class="item__info">
      <h3 class="item__info-title">{{props.item?.title}}</h3>
      <h6 class="item__info-category uppercase">{{props.item?.category}}</h6>
    </div>
  </div>
</router-link>
</template>

<style scoped lang="less">
.item {
  flex-basis: 49%;
  max-width: 49%;
  position: relative;
  margin-bottom: 2%;
  &.disabled {
    &:after {
      content: url("@/assets/disabled.svg");
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__wrapper {
    position: absolute;
    background: linear-gradient(0deg, #000000 -5.71%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 15px;
  }
  &__background {
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
    &.disabled {
      filter: grayscale(1);
    }
  }
}
</style>
