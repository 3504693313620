<script setup lang="ts">

import CircleButton from '@/components/Buttons/CircleButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import BackLine from '@/components/Lines/BackLine.vue'
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRoute, useRouter } from 'vue-router'
import { IValidateForm, Validate } from '@/helpers/validate'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const errors = ref<any>({})
const isLoading = ref<boolean>(false)
const username = ref<string>('')
const email = ref<string>('')
const password = ref<string>('')
const isAuth = computed(() => authStore.isAuth)
const currentField = ref<string>('')

const inFocus = (input: string) => {
  currentField.value = input
  delete errors.value[input]
}
const outFocus = (
  input: string,
  // ESLINT-ERROR: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  title: string,
  rules: string[]
) => {
  currentField.value = ''
  errors.value = Validate([
    {
      name: input,
      rules: rules as IValidateForm['rules'],
      value: model,
      ru: title
    }
  ])
}
const setLoading = (status: boolean) => {
  isLoading.value = status
}
const onSubmit = async () => {
  const validate = Validate([
    {
      name: 'username',
      rules: ['required', 'notemail', 'lower'],
      value: username.value,
      ru: 'ник'
    },
    {
      name: 'email',
      rules: ['required', 'email_split'],
      value: email.value,
      ru: 'e-mail'
    },
    {
      name: 'password',
      rules: ['required'],
      value: password.value,
      ru: 'пароль'
    }
  ])
  errors.value = {}
  if (Object.keys(validate).length) {
    errors.value = validate
  } else {
    setLoading(true)
    const result = await authStore
      .register({
        email: email.value,
        username: username.value,
        name: username.value,
        password1: password.value
      }).finally(() => setLoading(false))

    if (result.result?.message && result?.result?.message.toString().includes('success')) {
      authStore.setAuth({ result: { token: 'registertoken', username: username.value } })
      if (isAuth.value) {
        await router.push(route.redirectedFrom?.path || '/login/success')
      }
    } else {
      if (result?.errors.length) {
        errors.value.detail = result.errors
      }
    }
  }
}
</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="create-account px-10 mb-20">
    <div class="create-account__wrapper text-center pa-40">
      <h2 class="mb-10">Регистрация</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">Авторизация</h5>
      <form class="create-account__form" @submit.prevent="onSubmit" enctype="multipart/form-data">
        <BaseInput
          @focusin="inFocus('username')"
          @focusout="
          outFocus('username', username, 'ник', ['required', 'notemail', 'lower'])
        "
          v-model="username"
          type="text"
          class="mb-10 full-width"
          placeholder="Ник"
        />
        <BaseInput
          @focusin="inFocus('email')"
          @focusout="
          outFocus('email', email, 'e-mail', ['required', 'email_split'])
        "
          v-model="email"
          type="text"
          class="mb-10 full-width"
          placeholder="Почта"
        />
        <BaseInput
          @focusin="inFocus('password')"
          @focusout="
          outFocus('password', password, 'пароль', ['required'])
        "
          v-model="password"
          type="password"
          class="mb-10 full-width"
          placeholder="Пароль"
        />
        <BaseButton :disabled="isLoading || !!Object.keys(errors).length" class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Зарегистрироваться</BaseButton>
      </form>
      <div class="errors mb-10">
        <div v-for="(errorDetail, index) in errors?.detail" :key="index" class="error color-red">{{ errorDetail }}</div>
        <div class="error color-red" v-if="errors.email">{{errors?.email[0]}}</div>
        <div class="error color-red" v-if="errors.username">{{errors?.username[0]}}</div>
        <div class="error color-red" v-if="errors.password">{{errors?.password[0]}}</div>
      </div>
      <div class="text-gradient d-inline-flex flex-direction-column">
        <h5 class="text-center mb-10"><router-link :to="{name: 'login-restore'}">Забыли пароль? Восстановить</router-link></h5>
        <h6 class="text-center mb-10"><span>Продолжая, вы принимаете</span> условия использования<span>,<br>а также подтверждаете, что ознакомились<br>с документом</span> «Политика конфиденциальности».</h6>
        <br>
        <h5><span>Уже есть аккаунт?</span></h5>
        <h5><router-link :to="{name: 'login'}">Войти</router-link></h5>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.create-account {
  &__wrapper {
    position: relative;
    border: none;
    .text-gradient {
      h6, h5 {
        background:var(--gradient-1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        span {
          background: none;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }
}
</style>
