<script setup lang="ts">
import BaseButton from '@/components/Buttons/BaseButton.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { defineProps, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { FreeMode } from 'swiper/modules'

const props = defineProps<{
  seriesList: string[]
}>()

const route = useRoute()
const router = useRouter()
const mediaId = computed(() => route.params.media as string)
</script>

<template>
  <swiper
    :slidesPerView="'auto'"
    :space-between="10"
    class="series__slider"
    :freeMode="true"
    :modules="[FreeMode]"
  >
    <swiper-slide v-for="(series, index) in seriesList" :key="series" class="slide">
      <div @click="router.push({name: 'project-player', params: {media: series}})" class="slide__link">
        <BaseButton class="uppercase" bg="none" :border="series === mediaId ? 'gradient' : 'default'">Серия {{index + 1}}</BaseButton>
      </div>
    </swiper-slide>
  </swiper>
</template>

<style scoped lang="less">
.btn, .slide__link {
  width: 100px;
  min-width: 100px;
  user-select: none;
}
.slide {
  width: auto;
}
</style>
