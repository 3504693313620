import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import UIView from '@/views/UIView.vue'
import HomeView from '@/views/HomeView.vue'
import authRoutes from '@/router/auth'
import profileRoutes from '@/router/profile'
import projectRoutes from '@/router/project'

const anyRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ui',
    name: 'ui',
    component: UIView
  }
]

const routes = [...anyRoutes, ...authRoutes, ...profileRoutes, ...projectRoutes]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
