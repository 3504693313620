<script setup lang="ts">

import router from '@/router'
import BackLine from '@/components/Lines/BackLine.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import InterfaceSlider from '@/components/Sliders/InterfaceSlider.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const userStore = useUserStore()

const { user: userProfile } = storeToRefs(userStore)

const setDefaultTheme = async () => {
  const container = document.getElementById('app')
  if (container) {
    container.setAttribute('theme', 'b')
    await userStore.setTheme('b')
  }
}
</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="profile px-10 my-20">
    <div class="profile__wrapper text-center pa-40">
      <h2 class="mb-10">{{ userProfile?.name || '' }}</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">Интерфейс</h5>
      <div class="profile-actions">
        <BaseButton @click="setDefaultTheme" class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Вернуть по умолчанию</BaseButton>
        <BaseButton @click="router.go(-1)" class="large rounded margin-auto mb-20 full-width" bg="none" border="default">Назад</BaseButton>
      </div>
    </div>
    <InterfaceSlider />
  </div>
</template>
