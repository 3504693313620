import { Http } from '@/helpers'

export const getCatalog = async () => {
  return Http.get('/playlists')
}

export const getPlaylistById = async (id: string) => {
  return Http.get(`/playlists/${id}`)
}

export const getMediaById = async (id: string) => {
  return Http.get(`/media/${id}`)
}

export const searchMedia = async (search: string) => {
  return Http.get(`/search?t=${search}`)
}

export const likeUpdateById = async (id: string) => {
  try {
    await Http.post(`/media/${id}/actions`, { type: 'like' })
    const { data } = await getMediaById(id)
    return data.likes
  } catch (error) {
    return Promise.reject(error)
  }
}
