<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import BaseHeaderDemo from '@/components/Headers/BaseHeaderDemo.vue'
import BaseLine from '@/components/Lines/BaseLine.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import { ref } from 'vue'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const themes = ['b', 'w', 'r', 'g']
const selectedTheme = ref('b')
const changeTheme = async (id: string) => {
  const container = document.getElementById('app')
  if (container) {
    container.setAttribute('theme', id)
    await userStore.setTheme(id)
    selectedTheme.value = id
  }
}
</script>

<template>
<div class="interfaces">
  <swiper
    :slides-per-view="'auto'"
    :space-between="20"
    class="slider"
  >
    <swiper-slide :key="theme" v-for="theme in themes" class="interface">
      <div @click="changeTheme(theme)" class="interface__wrapper" :theme="theme">
        <div class="header">
          <BaseHeaderDemo />
        </div>
        <div class="base-line">
          <BaseLine text="твое личное время"/>
        </div>
        <div class="content pa-20">
          <BaseButton class="uppercase" bg="theme" border="none">ПРЕМЬЕРА</BaseButton>
          <h2 class="interface__wrapper-title mt-40">Мемасик</h2>
          <h5 class="interface__wrapper-description">Легкий и позитивный веб-сериал о жизни 13-летнего Мирона и его отношениях с мамой.</h5>
          <h6 class="interface__wrapper-category uppercase">Сериал 8 серий реалити 8+</h6>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</div>
</template>

<style scoped lang="less">
.slider {
  padding: 20px 10px;
  .interface {
    width: 90%;
    display: inline-flex;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    &__wrapper {
      background: var(--app-background-color);
      &-title, &-description, &-category {
        color: var(--typography-primary-color);
      }
    }
  }
}

</style>
