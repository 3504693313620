import { isInteger } from '../helpers'
import { IValidateObject } from './types'

// validators (false == OK, true == error)

export const requiredValidator = (input: IValidateObject): boolean =>
  !input.value

export const lowerValidator = (input: IValidateObject): boolean => !!(
  input.value.replace(/[^A-Z]/g, '') || input.value.replace(/[^А-Я]/g, '')
)

export const cyrAndNumberValidator = (input: IValidateObject): boolean => !/^[а-яё0-9\s]+$/i.test(input.value.toLowerCase())

export const cyrValidator = (input: IValidateObject): boolean => !!input.value.replace(/[А-Я]/gim, '')

export const loginValidator = (input: IValidateObject): boolean => !!input.value.replace(/^[\w-.+-]+/gm, '')

export const passwordValidator = (input: IValidateObject): boolean => !!input.value.replace(/[\w+^\\/\][!#$%^{})(":|]+/g, '')

export const emailSplitValidator = (input: IValidateObject): boolean => {
  const mail = input.value.split('@')
  return !!(
    mail.length &&
    mail[0] !== undefined &&
    mail[0].replace(/^[\w-.+-]+/gm, '')
  )
}

export const emailValidaror = (input: IValidateObject): boolean => !!input.value.replace(/^[\w-.]+@([\w-]+\.)+[a-z]{2,4}$/gm, '')

export const notemailValidator = (input: IValidateObject): boolean => !!input.value.length && input.value.includes('@')

export const minLengthValidator = (input: IValidateObject): boolean => input.value.length < input.params?.minLength

export const maxLengthValidator = (input: IValidateObject): boolean => input.value && input.value.length > input.params?.maxLength

export const symbolValidator = (input: IValidateObject): boolean => !/[!#$%^{}[)(":\\\]|0-9]/.test(input.value)

export const aliasNameValidator = (input: IValidateObject): boolean => {
  // english letters and digits, starts with letter, can't contain 'alias'
  if (!/^[A-Za-z0-9]+$/i.test(input.value)) return true
  if (!/^[A-Za-z]/i.test(input.value)) return true
  if (/^\w*(alias)\w*$/i.test(input.value)) return true
  return false
}

export const hasSpecialCharactersValidator = (input: IValidateObject) => {
  const specials = [
    '-',
    '_',
    '[',
    ']',
    '/',
    '{',
    '}',
    '(',
    ')',
    '*',
    '+',
    '?',
    '.',
    '\\',
    '^',
    '$',
    '|',
    '*',
    '&',
    '^',
    '%',
    '#',
    '@',
    '!',
    '<',
    '>',
    '`',
    '~',
    ','
  ]

  const regex = RegExp('[' + specials.join('\\') + ']', 'g')

  return regex.test(input.value)
}

export const hasSpaceInWordValidator = (input: IValidateObject) =>
  /\s/g.test(input.value.trim())

export const hasScenarioNameValidator = (input: IValidateObject) => (
  /^[A-Za-zА-Яа-я0-9]*(scen)[A-Za-zА-Яа-я0-9]*$/i.test(input.value) ||
    /^[A-Za-zА-Яа-я0-9]*(сцен)[A-Za-zА-Яа-я0-9]*$/i.test(input.value)
)

export const firstLetterSymbolValidator = (input: IValidateObject) =>
  input.value ? !/^[a-zа-я]/i.test(input.value.toLocaleLowerCase()) : false

export const uniqueValidator = (input: IValidateObject): boolean => {
  // value not in array of strings (case insensitive)
  if (!input.params?.array || !input.params.array.length) return false
  return !!input.params.array.filter(
    (item: string) => item.toLowerCase() === input.value.toLowerCase()
  ).length
}

export const numberCeilValidator = (input: IValidateObject): boolean => !isInteger(Number(input.value))

export const NDigitString = (input: IValidateObject) => {
  if (!input.value || !input.params?.digit) return false
  const expression = `^\\d{${input.params.digit}}$`
  const re = new RegExp(expression, 'g')
  const value = String(input.value)
  return !re.test(value)
}

export const anyNumberValidator = (input: IValidateObject) => {
  if (typeof input.value === 'object' || input.value === '') return false
  return !/^[-+]?[0-9]*[.,]?[0-9]+(?:[eE][-+]?[0-9]+)?$/i.test(input.value)
}

export const minNumberValidator = (input: IValidateObject): boolean => Number(input.value) < input.params?.minNumber

export const engUnderscoreValidator = (input: IValidateObject): boolean => {
  if (!input.value) return false
  return !/^[A-Za-z0-9\\_]+$/i.test(input.value.toLowerCase())
}

export const engRusUnderscoreHyphenSValidator = (input: IValidateObject): boolean => {
  if (!input.value) return false
  return !/^[A-Za-zА-Яа-я0-9\\_-\s]+$/i.test(input.value.toLowerCase())
}

export const onlyNumberValidator = (input: IValidateObject): boolean => {
  if (!input.value) return false
  return !/^\d+$/i.test(input.value.toLowerCase())
}
