import { Http } from '@/helpers'

export const getUserInfoById = async (userId: string) => {
  return Http.get(`/users/${userId}`, {
    headers: {
      Accept: 'application/json'
    }
  })
}
export const userUpdate = async (user: any) => {
  return Http.post(`/user/${user.username}`, {
    theme: user.description.theme
  }, {
    headers: {
      Accept: 'application/json'
    }
  })
}
export const getUserTheme = async (userId: string) => {
  return Http.get(`/user/${userId}`, {
    headers: {
      Accept: 'application/json'
    }
  })
}
