import { IErrors, IValidateObject, TValidateRule } from './types'
import * as validators from './validators'

// validate checks
/**
 * @description Формат validator - function, text - string
 */
const validateChecks = {
  required: {
    validator: validators.requiredValidator,
    text: (input: IValidateObject, rule: TValidateRule): string => {
      const defaultMessage = `Введите ${input.ru}`

      if (typeof rule === 'object') {
        return rule.message || defaultMessage
      }

      return defaultMessage
    }
  },
  lower: {
    validator: validators.lowerValidator,
    text: (input: IValidateObject): string =>
      `${input.ru} должен содержать строчные буквы`
  },
  cyr: {
    validator: validators.cyrValidator,
    text: 'Недопустимые символы'
  },
  cyrNumber: {
    validator: validators.cyrAndNumberValidator,
    text: 'Допустимы символы кириллицы и числа'
  },
  login: {
    validator: validators.loginValidator,
    text: (input: IValidateObject): string =>
      `Недопустимые символы. Проверьте правильность ввода ${input.ru}`
  },
  password: {
    validator: validators.passwordValidator,
    text: 'Недопустимые символы. Проверьте правильность ввода пароля'
  },
  email_split: {
    validator: validators.emailSplitValidator,
    text: (input: IValidateObject): string =>
      `Недопустимые символы в ${input.ru} до @`
  },
  email: {
    validator: validators.emailValidaror,
    text: (input: IValidateObject): string =>
      `Проверьте правильность ввода ${input.ru}`
  },
  notemail: {
    validator: validators.notemailValidator,
    text: (input: IValidateObject): string =>
      `Проверьте правильность ввода ${input.ru}`
  },
  min_length: {
    validator: validators.minLengthValidator,
    text: (input: IValidateObject): string =>
      `Не менее ${input.params?.minLength} символов`
  },
  max_length: {
    validator: validators.maxLengthValidator,
    text: (input: IValidateObject): string =>
      `Не более ${input.params?.maxLength} символов`
  },
  symbol: {
    validator: validators.symbolValidator,
    text: 'Обязательно наличие символа ! # $ % ^ { } [ ] ( ) " : \\ |'
  },
  alias: {
    validator: validators.aliasNameValidator,
    text: 'Допустимы символы a-z, цифры. Первый символ - буква. Не может содержать \'alias\''
  },
  scenarioNameExclude: {
    validator: validators.hasScenarioNameValidator,
    text: 'Не может содержать "scen", "сцен"'
  },
  spaceInWord: {
    validator: validators.hasSpaceInWordValidator,
    text: 'Пробелы недопустимы'
  },
  specialCharactersExclude: {
    validator: validators.hasSpecialCharactersValidator,
    text: 'Специальные символы недопустимы'
  },
  firstLetterSymbol: {
    validator: validators.firstLetterSymbolValidator,
    text: 'Первый символ должен быть буквой'
  },
  unique: {
    validator: validators.uniqueValidator,
    text: (input: IValidateObject): string =>
      `Значение ${input.ru} должно быть уникально`
  },
  ceilNumber: {
    validator: validators.numberCeilValidator,
    text: (input: IValidateObject) =>
      `Значение ${input.ru} должно быть целым числом`
  },
  NDigitString: {
    validator: validators.NDigitString,
    text: (input: IValidateObject): string =>
      `Значение ${input.ru} не является числом длины ${input.params?.digit}`
  },
  anyNumberValidator: {
    validator: validators.anyNumberValidator,
    text: (): string => 'Неверное число'
  },
  min_number: {
    validator: validators.minNumberValidator,
    text: (input: IValidateObject): string =>
      `Значение ${input.ru} должно быть больше чем ${input.params?.minNumber}`
  },
  eng_underscore: {
    validator: validators.engUnderscoreValidator,
    text: (): string => 'Допустимы символы a-z, цифры, символ "_"'

  },
  eng_rus_underscore_hyphen: {
    validator: validators.engRusUnderscoreHyphenSValidator,
    text: (): string => 'Допустимы буквы, цифры, символы: "_", "-"'
  },
  only_number: {
    validator: validators.onlyNumberValidator,
    text: (): string => 'Допустимы цифры'
  }
}

export type TValidatorNames = keyof typeof validateChecks

export interface IValidateFormRuleObj {
  name: TValidatorNames
  message?: string
}

export type TValidateFormRule = IValidateFormRuleObj | TValidatorNames

// ESLINT-DISABLE: Старая реализация
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IValidateForm<T = any>
  extends Omit<IValidateObject, 'value' | 'rules'> {
  value: T
  rules: TValidateFormRule[]
}

export const validateCurrentField = (form: IValidateForm): string[] => {
  const errors: string[] = []

  form.rules.forEach((rule) => {
    const ruleName = typeof rule === 'string' ? rule : rule.name

    if (validateChecks[ruleName] && validateChecks[ruleName].validator(form)) {
      const textSource = validateChecks[ruleName].text
      const text =
        typeof textSource === 'string' ? textSource : textSource(form, rule)

      errors.push(text)
    }
  })

  return errors
}

export const Validate = (form: IValidateForm[]): IErrors => {
  const errors: IErrors = {}

  form.forEach((input) => {
    const { name: inputName } = input

    const fieldErrors = validateCurrentField(input)

    if (fieldErrors.length) {
      errors[inputName] = fieldErrors
    }
  })
  return errors
}
